<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
          :plan-options="planOptions" @refetch-data="refetchData" />
    
        <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
          :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-2">
      <!-- <b-row>
        <b-col md="6" class="d-flex my-2">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            v-model="per_page"
            class="per-page-selector d-inline-block ml-2 w-25"
            :options="['5', '10', '20', '50', '100']"
            @input="changePagination()"
          />
        </b-col>
      </b-row> -->
      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="staticUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="onSortChanged"
      >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder" style="font-size: 15px">
            {{ data.index + 1 }}
          </span>
        </template>
        <!-- <template #thead-top="{ fields }">
          <tr>
            <th v-for="(field, index) in fields" :key="index">
              <template v-if="field.key == 'id' || field.key == 'Action'">
                &nbsp;
              </template>
              <template v-else>
                <b-form-input
                  v-model="columnFilters[field.key]"
                  @input="filterTable()"
                  placeholder="search..."
                />
              </template>
            </th>
          </tr>
        </template> -->
        
        <template #cell(f_name)="data">
          <div class="d-flex w-100 align-items-center">
            <div v-if="data.item.user || data.item.admin">
              <span class=" font-weight-bolder" style="font-size:17px">
                <b-avatar
                  size="32"
                  class="mr-1"
                  :src="
                    data.item.admin
                      ? data.item.admin.image
                      : data.item.user.image
                  "
                />{{ data.item && data.item.admin ? data.item.admin.f_name : (data.item && data.item.user ? data.item.user.f_name : '') }}
              </span>
            </div>
          </div>
        </template>
        <template #cell(title)="data">
          <div
            style="cursor: pointer"
            class="d-flex w-100 d-flex align-items-center"
            @click="openVideoModal(data.item)"
          >
            <!-- <video v-else class="text-dark w-100 ImageLink" ref="videoPlayer" controls>
                            <source :src="data.item.url" type="video/mp4">
                          </video> -->
            <template v-if="data.item && data.item.thumbnail">
              <img :src="data.item.thumbnail" width="50" height="50" />
            </template>
            <template v-else>
              <span>
                <img
                  style="height: 40px; width: 40px"
                  src="../../../../../public/no-image.png"
                  alt=""
                />
              </span>
            </template>
            <span class="font-weight-bold ml-1 text-primary title">{{
              data.item.title
            }}</span>
          </div>
        </template>
        <template #cell(videocategory_ids)="data">
          <div class="d-flex w-100 align-items-center">
            <span class="font-weight-bolder title">
              <p class="m-0">
                {{
                  data.item && data.item.videocategory
                    ? data.item.videocategory.name
                    : ""
                }}
              </p>
            </span>
          </div>
        </template>
        <template #cell(Action)="data">
          <div class="d-flex align-items-center" style="cursor: pointer" @click.prevent="handleEditForm(data.item, true)">
            <b-badge variant="light-primary">Unhide Videos</b-badge>
          </div>
        </template>
      </b-table>
      <b-modal
        hide-footer
        v-model="EditVideo"
        no-close-on-backdrop
        centered
        size="md"
      >
        <validation-observer ref="Rules">
          <b-row class="p-2">
            <b-col md="12" class="d-flex align-items-center flex-column">
              <h2 style="font-size: 26px" class="font-weight-bolder lableTitle">
                Video Information
              </h2>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="font-weight-bolder mt-1" for="h-trainer"
                  >Enter Video Title</label
                >
                <b-form-input v-model="videoTitle" placeholder="Title" />
              </b-form-group>
            </b-col>
            <b-col md="12" class="d-flex justify-content-center mt-2">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="submitForm()"
                style="font-family: Public Sans"
              >
                Submit
              </b-button>
              <b-button
                type="reset"
                @click="CloseModal"
                variant="primary"
                style="font-family: Public Sans"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
      <div class="w-100 d-flex justify-content-between p-1">
        <!-- Pagination -->
        <div>
          <span class="text-nowrap">
            Showing {{ paginationStart }} to {{ paginationEnd }} of
            {{ paginationTotal }}</span
          >
        </div>
        <b-pagination
          :total-rows="paginationTotal"
          :per-page="per_page"
          v-model="paginationValue"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="changePagination()"
        >
          <!-- <div>
            <button
              @click="goToPage(paginationValue - 1)"
              :disabled="paginationValue === 1"
            >
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </button>
            <button
              @click="goToPage(paginationValue + 1)"
              :disabled="paginationValue * per_page >= paginationTotal"
            >
              <feather-icon icon="ChevronRightIcon" size="18" />
            </button>
          </div> -->
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
    <b-modal
      v-model="openVideoModel"
      :title="selectedVideo ? selectedVideo.title : ''"
      class="text-primary"
      hide-footer
    >
      <video
        :src="selectedVideo ? selectedVideo.url : ''"
        controls
        autoplay
        class="w-100"
      ></video>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BIcon,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormFile,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import _ from "lodash";

export default {
  components: {
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BIcon,
    BImg,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      tableColumns: [
        {
          key: "id",
          label: "NO",
          sortable: true,
        },
        { key: "title", label: "Video", sortable: true },
        { key: "f_name", label: "Client", },
        { key: "videocategory_ids", label: "Video Category", },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: "view_count", label: "Views",},
        // { key: 'balance', sortable: true },
        { key: "Action", label: "Action" },
      ],
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      videoTitle: "",
      isSortDirDesc: true,
      EditVideo: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      staticUsers: [],
      isLoading: false,
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      params:{},
      columnFilters:{},
      selectedVideo:0,
      openVideoModel: false,
    };
  },
  computed: {
    processedUsers() {
      return this.staticUsers.map((user) => {
        const avatars = user.avatars || [];
        const topThreeAvatars = avatars.slice(0, 3);
        const remainingCount = Math.max(avatars.length - 3, 0);
        return { ...user, topThreeAvatars, remainingCount };
      });
    },
  },
  mounted() {
    this.getVideo();
  },
  methods: {
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getVideo();
    },
    // filterTable :_.debounce(function (){
    //   this.params={}
    //   for(let key in this.columnFilters){
    //     if(this.columnFilters[key] && typeof this.columnFilters[key] == 'object'){
    //       this.params[key] = this.columnFilters[key].map((inner) =>inner.id).join(',')
    //     }
    //     else{
    //       this.params[key] = this.columnFilters[key]
    //     }
    //   }
    //   this.params.page = this.paginationValue
    //   this.getVideo()
    // },1000),
    
    handleEditForm(item) {
      this.EditVideo = true;
      this.videoTitle = item.title;
    },
    goToPage(page) {
      if (page >= 1 && page <= Math.ceil(this.paginationTotal / this.per_page)) {
        this.paginationValue = page;
        this.getClientData();
      }
    },
    async getVideo() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}video/get-hidden-video`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params:params
      })
        .then((response) => {
          this.isLoading = false;
          this.staticUsers = response.data.data;
          this.paginationTotal = response.data.total;
          this.paginationStart = response.data.from;
          this.paginationEnd = response.data.to;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },
    openVideoModal(item) {
      this.selectedVideo = item;
      this.openVideoModel = true;
    },
    handleEditForm(item, hide) {
      const { id, title } = item;
      this.videoTitle = title;

      this.EditVideo = true;

      const status = hide ? 1 : 0;

      this.customerData = { title: title, status, id };
    },
   async handleEditVideo() {
      const { title, status, id } = this.customerData;

      const requestOptions = {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}video/update/${id}`,
        data: JSON.stringify({ title : this.videoTitle, status, id }),
      };
     await axios(requestOptions)
        .then(() => {
          this.$swal({
            title: "Submitted",
            text: "Video Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getVideo();
          this.EditVideo = false;
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: `${error}`,
            icon: "error",
            timer: 5000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    submitForm() {
      this.handleEditVideo();
    },
    async removeVideo(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}video/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.staticUsers.findIndex(
                  (item) => item.id === id
                );
                if (index !== -1) {
                  this.staticUsers.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    changePagination() {
      this.getVideo();
    },
    openProfile(item) {
      const userId = item.id;
      this.$router.push({ path: `/trainermanagement/${userId}` });
    },
    openTrainerProfile(item) {
      const userId = item.Trainer;
      this.$router.push({ path: `/trainerprofile/${userId}` });
    },
    openModal() {
      this.AddNewTrainer = true;
    },
    CloseModal() {
      this.EditVideo = false;
    },
  },
};
</script>
<style>
.avatar-container {
  display: flex;
  align-items: center;
}

.title {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
}

.remaining-count {
  margin-left: 5px;
  font-weight: bold;
}
</style>